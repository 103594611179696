import { render, staticRenderFns } from "./Overlay.vue?vue&type=template&id=49aee7b7&scoped=true&"
import script from "./Overlay.vue?vue&type=script&lang=js&"
export * from "./Overlay.vue?vue&type=script&lang=js&"
import style0 from "./Overlay.vue?vue&type=style&index=0&id=49aee7b7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49aee7b7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsLoader: require('/home/ticketshoplv/public_html/ic3peak.tour.tickets/components/elements/Loader.vue').default})
