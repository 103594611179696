import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=59ba9467&scoped=true&"
import script from "./SearchBar.vue?vue&type=script&lang=js&"
export * from "./SearchBar.vue?vue&type=script&lang=js&"
import style0 from "./SearchBar.vue?vue&type=style&index=0&id=59ba9467&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ba9467",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventCompact: require('/home/ticketshoplv/public_html/ic3peak.tour.tickets/components/Event/Compact.vue').default})
